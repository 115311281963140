<template>
    <div id="eventListContainer" class="event-item-list-scroll" :style="{ height: iheight }">
        <!--        <i-pull-refresh v-if="eventList.length" v-model="refreshing" @refresh="onRefresh">-->
        <i-list v-if="eventList.length" v-model="loading" :offset="1" :finished="finished" finished-text="没有更多了"
                :immediate-check="false"
                @load="nextPage">
            <div v-for="(item, index) in eventList" class="event-item" :class="{ opHomeOn: backListOn == index }"
                 :ref="'eventListItem_' + index"
                 :key="item.MiceId" @click="openWaitOrder(item, index)">
                <template v-if="config['cardTemplate']">
                    <component :is="$evalTemplate('Vnode', config['cardTemplate'], item)"></component>
                </template>
                <template v-else>
                    <p class="event-item-title">{{ item.Name }}</p>
                    <p class="event-item-order-number">
                        <template v-if="isCSPC">{{ item.PONum || "暂无" }}</template>
                        <template v-else>{{ item.EventNum || "暂无" }}</template>
                    </p>
                    <p class="event-item-order-number" v-if="item.BudgetNumber">
                        {{ item.BudgetNumber }}
                    </p>
                    <div class="event-item-content">
                        <div v-if="isSYJT" class="full">
                            <i-icon :size="14" color="#13161A" name="icon-huiyi2"/>
                            {{ item.TypeDictVal }}
                        </div>
                        <div class="full">
                            <i-icon :size="14" color="#13161A" name="icon-rili"/>
                            {{ item.DtStart.split("T")[0] }} ~ {{ item.DtEnd.split("T")[0] }}
                        </div>
                        <div>
                            <i-icon :size="14" color="#13161A" name="icon-weizhi"/>
                            {{ item.Province && item.city ? `${item.Province}-${item.city}` : item.City }}
                        </div>
                        <div>
                            <i-icon :size="14" color="#13161A" name="icon-banhuiren"/>
                            {{ item.OwnerName }}
                            <template v-if="isCSPC"> ({{ item.OwnerAccount }})</template>
                        </div>
                    </div>
                    <event-tag :status="item.Status" :statusTxt="item.StatusTxt"/>
                    <div class="extTag" v-if='JSON.parse(item.ExtData || "{}").IsReturnMice'>退回</div>
                </template>
            </div>
        </i-list>
        <!--        </i-pull-refresh>-->
        <empty-box v-if="!eventList.length"/>
    </div>
</template>

<script>
import EventTag from "@/components/EventTag";
import emptyBox from "@/components/iEmpty";
import {getCookie} from "tiny-cookie";
import {Dialog} from 'vant'
import PublicMethod from "@/utils/publicFun.js";

export default {
    name: "EventItemList",
    components: {
        EventTag,
        emptyBox,
    },
    computed: {
        isGroupMeeting() {
            return getCookie('tenant') === 'pfizer' && this.$smartStorage.get('selectedEventType') === 'groupMeeting'
        },
    },
    data() {
        return {
            config: [],
            iheight: "calc(100vh - 1.59rem)",
            pageIndex: 1,
            pageSize: 10,
            lastPageIndex: null,
            lastPageSize: null,
            lastEventStatus: "",
            lastSearchKey: "",
            eventList: [],
            backListOn: 0,
            loading: false,
            finished: false,
            eventListContainerScroll: this.$smartStorage.get('listScrollTop') || 0,
            isSYJT: false,
            isCSPC: false,
            isImmediateSearch: false, // 是否立即搜索
            searchTemplate: {
                $or: [
                    {
                        ExtNum: "/%s/",
                    },
                    {
                        Name: "/%s/",
                    },
                    {
                        EventNum: "/%s/",
                    },

                    {
                        PONum: "/%s/",
                    },
                ],
            },

            filterData: null,
            refreshing: false,
            queryId: ""
        };
    },
    async created() {
        const directoryId = this.$cookie.get("tenant_id") || this.$smartStorage.get("tenant_id");
        if (directoryId == "7faf5393-8a70-11ec-89d2-6a0616dfdee4") {
            this.isSYJT = true;
        }


        // 获取显示模板
        let params = {
            collection: "cfg-ievent-eventList-template",
            filter: {
                platform: "app",
                tenantCode: this.$cookie.get("tenant") || this.$smartStorage.get("tenant"),
                tenantId: this.$cookie.get("tenant_id") || "",
            },
        };
        if (this.$smartStorage.get('homeQueryListExpr')) {
            try {
                eval(this.$smartStorage.get('homeQueryListExpr')?.cardExpr || '');
            } catch (error) {
                console.log("====解析首页查询会议列表card的表达式", error, this.$smartStorage.get('homeQueryListExpr'));
            }
        }

        let res = await this.$service.GetSettings(params);
        if (res && res.success) {
            // setTimeout(async () => {
            this.config["cardTemplate"] = res.content?.template || '';
            // this.queryId = res.content?.params?.queryId || '';
            let lastListParams = this.$smartStorage.get('lastListParams') || {};
            console.log('lastListParams',lastListParams);
            // 为了解决住友会议状态第一个是'会议执行'却获取了'全部'的问题，而不从storage的MyEventTabs中取，是因为兄弟组件eventFilter生命周期执行顺序不可控，为了保证总是能拿到，只能再发一次请求。
            // （更新）lastListParams里面的状态在App.vue中被删除了，原因未知，但是这里要获取状态，保证返回列表的时候能够获取到对应状态的列表
            // 故给使用currentEventTypeTab作为指针，获取当前的会议状态
            if (!(lastListParams.hasOwnProperty('eventStatus')) && getCookie('tenant') === 'dsm') {
                const arr = await PublicMethod.getStatusOfEvents();
                lastListParams.eventStatus = arr[0].Val;
            }else {
                const arr = this.$smartStorage.get(`${this.$smartStorage.get('selectedEventType')}EventTabs`) || this.$smartStorage.get(`MyEventTabs`);
                const index = parseInt(this.$smartStorage.get('currentEventTypeTab'));
                lastListParams.eventStatus = arr[index].code || arr[index].Val || null;
            }
            if (!this.isImmediateSearch) {
                await this.queryList({
                    pageIndex: 1,
                    pageSize: (lastListParams.pageIndex * lastListParams.pageSize) || this.pageSize,
                    eventStatus: lastListParams.eventStatus ?  lastListParams.eventStatus : lastListParams.eventStatus === 0 ? 0 : '', // 0不能过滤掉
                    searchKey: lastListParams.searchKey || '',
                    filterData: lastListParams.filterData || null,
                    lastPageIndex: lastListParams.pageIndex || 1
                });
            }

            if (this.$smartStorage.get('backListOn')) {
                setTimeout(() => {
                    let _index = parseInt(this.$smartStorage.get('backListOn')) || 0
                    if (this.$refs['eventListItem_' + _index]) {
                        this.$refs['eventListItem_' + _index][0] &&
                        this.$refs['eventListItem_' + _index][0].scrollIntoViewIfNeeded()
                    } else if (this.$refs['eventListItem_' + (_index - 1)]) {
                        this.$refs['eventListItem_' + (_index - 1)][0] &&
                        this.$refs['eventListItem_' + (_index - 1)][0].scrollIntoViewIfNeeded()
                    }
                }, 500)
            }
            if (res.content?.filterConfig && Array.isArray(res.content.filterConfig) && res.content.filterConfig.length) {
                this.$root.$eventHub.$emit("setFilterConfig", res.content?.filterConfig || []);
            }
            // }, 200)

        }
    },
    mounted() {
        if (this.$route.query.type == "callCar") {
            this.iheight = "calc(100vh - 0.7rem)";
        }
        if (!this.isImmediateSearch) { // 在住友的会务支持页面，要求不立即搜索，这是闫海龙的要求
            // 其他组件通知拉取列表数据
            this.$root.$eventHub.$on("updateEventList", ({lastReload, eventStatus, searchKey, filterData}) => {
                console.log("====通知拉取数据", {lastReload, eventStatus, searchKey, filterData});
                if (filterData) this.filterData = filterData
                if (lastReload) {
                    this.queryList({
                        pageIndex: this.lastPageIndex,
                        pageSize: this.lastPageSize,
                        eventStatus: this.lastEventStatus,
                        searchKey: this.lastSearchKey,
                        filterData
                    });
                } else {
                    this.queryList({
                        pageIndex: 1,
                        pageSize: this.pageSize,
                        eventStatus: eventStatus,
                        searchKey: searchKey,
                        filterData
                    });
                }
            });
        }
    },
    destroyed() {
        this.$root.$eventHub.$off("updateEventList");
    },
    // keep-alive缓存后，重新进入缓存组件时触发
    activated() {
        document.getElementById("eventListContainer").scrollTop = this.eventListContainerScroll;
    },
    // keep-alive缓存后，离开缓存组件时触发
    deactivated() {
    },
    methods: {
        // 下拉刷新
        onRefresh() {
            this.finished = false;
            this.eventList = []
            this.pageIndex = 0
            document.getElementsByClassName('event-item-list-scroll')[0].scrollTop = 0

            this.loading = true;
            this.nextPage()
        },
        async queryList({pageIndex, pageSize, eventStatus, searchKey, filterData, lastPageIndex = null}) {
            let context = this;
            this.lastPageIndex = lastPageIndex || pageIndex;
            this.lastPageSize = pageSize;
            console.log('eventStatus',eventStatus);
            this.lastEventStatus = eventStatus;
            this.lastSearchKey = searchKey;
            this.filterData = filterData
            if (pageIndex == 1) {
                this.eventList = [];
                this.pageIndex = 1;
                this.backListOn = 0;
                this.eventListContainerScroll = 0;
            }
            if (this.$route.query.type == "callCar") {
                this.queryListOfCar(pageIndex, pageSize, searchKey)
                return false
            }

            let param = {
                QueryId: this.queryId || "615",
                QueryId4Count: "616",
                cluster: "default",
                UsePaging: true,
                parameters: {
                    _pageSize: pageSize,
                    _pageIndex: pageIndex,
                    UserId: this.$smartStorage.get("userMsg")?.userId || ''
                }
            }

            // 根据会议类型来设置获取会议列表的参数
            if (this.$route.query.eventType || this.$smartStorage.get('homeQueryListExpr')) {
                let homeQueryListExpr = this.$smartStorage.get('homeQueryListExpr') || null
                if (homeQueryListExpr) {
                    try {
                        eval(homeQueryListExpr?.listExpr || "");
                    } catch (error) {
                        console.log("====解析首页查询会议列表的表达式", error, homeQueryListExpr);
                    }
                } else {
                    switch (this.$route.query.eventType) {
                        case "created":
                        case "needApproval":
                            param.parameters.UserId = this.$smartStorage.get("userMsg")?.userId || '';
                            break;
                        default:
                            break
                    }
                }
            }
            if (eventStatus !== '') {
                param.parameters.Status = eventStatus;
            }
            if (searchKey) {
                param.parameters.KeyWord = searchKey;
            }
            if (filterData) {
                param.parameters = Object.assign(param.parameters, filterData);
            }
            const res = await this.$service.GetQueryData(param);
            if (res.success) {
                console.log("====会议列表", res.content.rows);
                if (pageIndex == 1) {
                    this.eventList = []
                    this.refreshing = false
                }
                let eventList = [];
                if (getCookie('tenant_code') === 'dsm' && this.$smartStorage.get("selectedEventType") === 'created') {
                    eventList = await this.DSMGetUnsubmitOrders(res.content.rows);
                } else {
                    eventList = res.content.rows;
                }
                eventList.map((x) => {
                    // 属性首字母大写
                    let keys = Object.keys(x);
                    keys.map((key) => {
                        let newKey = key.replace(key[0], key[0].toUpperCase());
                        x[newKey] = x[key];
                        // 如果有showApplyStatus，且为字符串的true，才展示，为false都不展示
                        x['ShowApplyStatus'] = this.$route.query.showApplyStatus === 'true';
                        delete x[key];
                    });
                });
                let newList = [...this.eventList, ...eventList];
                let uniqueList = [];
                newList.map((x) => {
                    if (!uniqueList.find((y) => y.MiceId == x.MiceId)) {
                        uniqueList.push(x);
                    }
                });

                this.eventList = uniqueList;

                console.log(this.eventList);
                this.loading = false;
                this.finished = eventList.length < this.pageSize;
            }
        },
        // 住友获取未提交审批订单
        DSMGetUnsubmitOrders(list) {
            const params = JSON.parse(JSON.stringify(list)).map(i => i.miceId);
            return new Promise(resolve => {
                this.$service.GetApprovalCountByMices({ProcessIds: params}).then(res => {
                    if (res.success && res.content.length) {
                        const listMapper = new Map();
                        res.content.forEach(i => {
                            listMapper.set(i.processId, i.count);
                        })
                        resolve(list.map(x => {
                            return {...x, unsubmitOrder: listMapper.get(x.miceId)}
                        }))
                    }
                })
            })
        },
        // 代叫车业务单独处理接口
        async queryListOfCar(pageIndex, pageSize, searchKey) {
            const res = await this.$service.GenericPost({
                api: "/api/ndata/xmeta/paging",
                params: {
                    clientAction: "query",
                    meta: {
                        find: {
                            searchValue: searchKey,
                        },
                        projection: {
                            "*": 1,
                        },
                        sort: {
                            createdOn: -1,
                        },
                    },
                    object: "event",
                    schema: "smartx_crossevent",
                    PageIndex: pageIndex,
                    PageSize: pageSize,
                },
            });
            if (res.success) {
                console.log("====会议列表", res.content.rows);
                if (pageIndex == 1) {
                    this.eventList = []
                    this.refreshing = false
                }
                const eventList = res.content.rows;
                let newList = [...this.eventList, ...eventList];
                let uniqueList = [];
                newList.map((x) => {
                    // 属性首字母大写

                    let keys = Object.keys(x);
                    keys.map((key) => {
                        let newKey = key.replace(key[0], key[0].toUpperCase());
                        x[newKey] = x[key];
                        delete x[key];
                    });

                    if (!uniqueList.find((y) => y.MiceId == x.MiceId)) {
                        uniqueList.push(x);
                    }
                });
                this.eventList = uniqueList;
                this.loading = false;
                this.finished = eventList.length < this.pageSize;
            }
        },
        openWaitOrder(item, index) {
            if (this.$route.query.blockToDetails) return; // 阻止跳转详情
            // 代叫车进入的列表项点击后进入新的一个会议详情
            if (this.$route.query.type == "callCar") {
                let query = {orderId: item.OrderId, miceId: item.MiceId, type: "callCar"};
                this.$router.push({name: "eventDetail", query: query});
            } else {
                this.$smartStorage.set('backListOn', index)
                this.$smartStorage.set('lastListParams', {
                    pageIndex: this.lastPageIndex,
                    pageSize: this.pageSize,
                    eventStatus: this.lastEventStatus,
                    searchKey: this.lastSearchKey,
                    filterData: this.filterData,
                })
                console.log('状态----',this.$smartStorage.get('lastListParams'));
                this.backListOn = index;
                let query = {orderId: item.OrderId, miceId: item.MiceId};
                if(getCookie('tenant') === 'dsm' && this.$route.name === 'eventsComponent'){ // 住友的会务支持要跳转回来，存入回跳标识
                    this.$smartStorage.set('fullPath', this.$route.fullPath);
                }
                // 辉瑞拼团有单独的详情
                this.$router.push({name: this.isGroupMeeting ? "pfizerEventDetail" : "eventDetail", query: query});
            }
        },
        nextPage() {
            if (this.finished) return false;
            this.queryList({
                pageIndex: ++this.lastPageIndex,
                pageSize: this.pageSize,
                eventStatus: this.lastEventStatus,
                searchKey: this.lastSearchKey,
                filterData: this.filterData
            });
        },
        applyForCar(item) {
            const _this = this;
            Dialog.confirm({
                title: '提示',
                message: '是否确认申请叫车',
                confirmButtonColor: document.body.style.getPropertyValue('--themeColor'),
            })
                .then(() => {
                    const params = {"flag": 1, "status": 1, "agentType": 2, "miceId": item.MiceId};
                    _this.$service.applyForUseCar(params).then(res => {
                        _this.$itoast('成功');
                        // 通过父组件取更新列表，不直接更新
                        _this.$emit('updateList');
                        // _this.$root.$eventHub.$emit("updateEventList", {
                        //     searchKey: _this.searchKey,
                        //     filterData: {
                        //         Status: this.lastEventStatus,
                        //         Flag: 2
                        //     }
                        // })
                    })
                })
                .catch(() => {
                    // on cancel
                });
        },
    },
};
</script>

<style lang="less" scoped>
.event-item-list-scroll {
    height: calc(100vh - 1.59rem);
    overflow-y: scroll;
    padding: 0.1rem 0.1rem 0 0.1rem;
    box-sizing: border-box;
    pointer-events: auto !important;

    * {
        pointer-events: auto !important;
    }

    .event-item {
        background: linear-gradient(-55deg, #FFFFFF, #F8F8F8);
        box-shadow: 0px 0.02rem 0.08rem 0px rgba(0, 0, 0, 0.15);
        border-radius: 0.04rem;
        padding: 0.15rem;
        box-sizing: border-box;
        text-align: left;
        margin-bottom: 0.08rem;
        position: relative;

        .operation_btn {
            //width: 2rem;
            height: 0.32rem;
            text-align: right;
            line-height: 0.32rem;
            //background-color: #2d82f0;
            color: #fff;

            .inner_button {
                padding: 0.08rem .12rem;
                border-radius: .04rem;
                background-color: var(--themeColor);
                box-shadow: 0 0.44vw 2.22vw 0 var(--opacityColor);
            }
        }

        .event-item-title {
            font-size: 0.16rem;
            color: #13161a;
            text-align: left;
            font-weight: 700;
            width: 2.3rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .event-item-order-number {
            color: #90939a;
            font-size: 0.12rem;
            text-align: left;
        }

        .unsubmit-order {
            margin-top: 0.1rem;
        }

        .event-item-content {
            display: flex;
            flex-wrap: wrap;
            margin-top: 0.1rem;
            align-items: center;
            line-height: 1;

            & > div {
                display: flex;
                align-items: center;
                color: #13161a;
                font-size: 0.14rem;

                &.full {
                    width: 100%;
                }

                svg {
                    margin-right: 0.04rem;
                }
            }

            & > p:first-child {
                margin-right: 0.15rem;
            }
        }

        .extTag {
            background: var(--opacityColor);
            border-radius: 0.04rem;
            font-size: 0.12rem;
            font-weight: 500;
            color: var(--themeColor);
            right: 0.15rem;
            top: 0.35rem;
            position: absolute;
            padding: 0.04rem;
        }

        .extTagRed {
            background: #FFECED;
            border-radius: 0.04rem;
            font-size: 0.12rem;
            font-weight: 500;
            color: #FF484D;
            right: 0.15rem;
            top: 0.35rem;
            position: absolute;
            padding: 0.04rem;
        }
    }

    .opHomeOn {
        animation: mymove 1s;
        -webkit-animation: mymove 1s;
    }
}

@keyframes mymove {
    from {
        opacity: 0.1;
    }

    to {
        opacity: 1;
    }
}
</style>
