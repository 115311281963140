var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "event-item-list-scroll",
      style: { height: _vm.iheight },
      attrs: { id: "eventListContainer" },
    },
    [
      _vm.eventList.length
        ? _c(
            "i-list",
            {
              attrs: {
                offset: 1,
                finished: _vm.finished,
                "finished-text": "没有更多了",
                "immediate-check": false,
              },
              on: { load: _vm.nextPage },
              model: {
                value: _vm.loading,
                callback: function ($$v) {
                  _vm.loading = $$v
                },
                expression: "loading",
              },
            },
            _vm._l(_vm.eventList, function (item, index) {
              return _c(
                "div",
                {
                  key: item.MiceId,
                  ref: "eventListItem_" + index,
                  refInFor: true,
                  staticClass: "event-item",
                  class: { opHomeOn: _vm.backListOn == index },
                  on: {
                    click: function ($event) {
                      return _vm.openWaitOrder(item, index)
                    },
                  },
                },
                [
                  _vm.config["cardTemplate"]
                    ? [
                        _c(
                          _vm.$evalTemplate(
                            "Vnode",
                            _vm.config["cardTemplate"],
                            item
                          ),
                          { tag: "component" }
                        ),
                      ]
                    : [
                        _c("p", { staticClass: "event-item-title" }, [
                          _vm._v(_vm._s(item.Name)),
                        ]),
                        _c(
                          "p",
                          { staticClass: "event-item-order-number" },
                          [
                            _vm.isCSPC
                              ? [_vm._v(_vm._s(item.PONum || "暂无"))]
                              : [_vm._v(_vm._s(item.EventNum || "暂无"))],
                          ],
                          2
                        ),
                        item.BudgetNumber
                          ? _c(
                              "p",
                              { staticClass: "event-item-order-number" },
                              [_vm._v(" " + _vm._s(item.BudgetNumber) + " ")]
                            )
                          : _vm._e(),
                        _c("div", { staticClass: "event-item-content" }, [
                          _vm.isSYJT
                            ? _c(
                                "div",
                                { staticClass: "full" },
                                [
                                  _c("i-icon", {
                                    attrs: {
                                      size: 14,
                                      color: "#13161A",
                                      name: "icon-huiyi2",
                                    },
                                  }),
                                  _vm._v(" " + _vm._s(item.TypeDictVal) + " "),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            { staticClass: "full" },
                            [
                              _c("i-icon", {
                                attrs: {
                                  size: 14,
                                  color: "#13161A",
                                  name: "icon-rili",
                                },
                              }),
                              _vm._v(
                                " " +
                                  _vm._s(item.DtStart.split("T")[0]) +
                                  " ~ " +
                                  _vm._s(item.DtEnd.split("T")[0]) +
                                  " "
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c("i-icon", {
                                attrs: {
                                  size: 14,
                                  color: "#13161A",
                                  name: "icon-weizhi",
                                },
                              }),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    item.Province && item.city
                                      ? item.Province + "-" + item.city
                                      : item.City
                                  ) +
                                  " "
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c("i-icon", {
                                attrs: {
                                  size: 14,
                                  color: "#13161A",
                                  name: "icon-banhuiren",
                                },
                              }),
                              _vm._v(" " + _vm._s(item.OwnerName) + " "),
                              _vm.isCSPC
                                ? [
                                    _vm._v(
                                      " (" + _vm._s(item.OwnerAccount) + ")"
                                    ),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]),
                        _c("event-tag", {
                          attrs: {
                            status: item.Status,
                            statusTxt: item.StatusTxt,
                          },
                        }),
                        JSON.parse(item.ExtData || "{}").IsReturnMice
                          ? _c("div", { staticClass: "extTag" }, [
                              _vm._v("退回"),
                            ])
                          : _vm._e(),
                      ],
                ],
                2
              )
            }),
            0
          )
        : _vm._e(),
      !_vm.eventList.length ? _c("empty-box") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }